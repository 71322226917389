.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: transparent;
  min-width: 160px;
  background-color: #fff;
  /*box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  */
  z-index: 1;
}
.dropdown:hover .dropdown-content {
  display: block;
}
