.service-container {
  width: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  padding: 2rem 0;
}
.service {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-img {
  width: 100%;
  height: 100%;
  flex: 1;
}
.service-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.service-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.service-text h2 {
  font-size: 1.9rem;
  font-family: monospace;
  font-weight: bold;
  font-style: italic;
}
.service-text p {
  text-align: justify;
  width: 90%;
}
.service-text .button {
  width: 12rem;
  height: 2.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 1px solid #555;
  background-color: #fff;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  padding: 1.2rem;
  color: #000;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.service-text .button span {
  margin-left: 0.3rem;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
}
.service-text .button:hover {
  background-color: #000;
  color: #fff;
}
.service-text .button:hover span {
  transform: scale(1, 2) translateX(10px);
  -webkit-transform: scale(1, 2) translateX(10px);
  -moz-transform: scale(1, 2) translateX(10px);
  -ms-transform: scale(1, 2) translateX(10px);
  -o-transform: scale(1, 2) translateX(10px);
  -webkit-transform: scale();
}

@media (max-width: 700px) {
  .service {
    flex-direction: column;
    gap: 1rem;
  }
}
@media (max-width: 380px) {
  .service {
    width: 100%;
  }
  .service-img {
    width: 80%;
  }
  .service-text {
    width: 80%;
  }
  .service-text .button {
    width: 50%;
    font-size: 0.8rem;
    padding: 1rem;
  }
}
